.aboutUsContainer {
  z-index: 1;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
}

.landingSectionTitle {
  font-weight: 600;
}
