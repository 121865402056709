.footerHr {
  height: 2px;
  color: white;
  background-color: white;
  opacity: 100%;
}

.footerDiv {
  background-color: black;
}

.footerA {
  color: white;
  text-decoration: none;
}

.footerA:hover {
  color: #f7727c;
  text-decoration: none;
}
