.landingHero {
  z-index: 1;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover;
  color: #f7727c;
  height: 100vh !important;
}

.subtitleTextAccent {
  font-weight: bold;
  font-family: 'Playfair Display', serif;
}
