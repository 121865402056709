.benefitsImageDiv {
  width: 30%;
}

.benefitsText {
  width: 70% !important;
}

.benefitsImage {
  width: 100%;
  height: auto;
}
