.whyUsItemEm {
  font-weight: bold;
  font-style: normal;
  color: rgb(103, 103, 103);
}

.screenshots {
  width: 100%;
  height: auto;
}
